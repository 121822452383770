.form-control {
  font-size: 15px;
  padding: 19px 12px;
}

.input-group {
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #525f7f;
    }
  }
}

.input-group-text {
  background-color: #f7fafc;
  color: #525f7f;
}

.hr-user {
  margin-bottom: 1px;
}
